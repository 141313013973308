import Obfuscate from 'react-obfuscate';
import logo from './ga_centred_logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          We'll be back soon!
        </p>
        <p>We're undergoing a quick refresh. In the meantime, please contact us via email for any business inquiries: <br />
    Email:{' '}
    <Obfuscate
    style={{ color: 'white' }}
      email="corporate@giantadvents.co.uk"
      headers={{
        subject: 'Giant Advents Website Inquiry',
      }}
    />
        </p>
      </header>
    </div>
  );
}

export default App;
